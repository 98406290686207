import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import * as gridStyles from "./paintings/paintingGrid.module.css";
import * as yearStyles from "./yearsGrid.module.css";
import { BiDotsVertical } from 'react-icons/bi'

import { myContext } from './PageContext';


export default function YearGrid(props) {
      const orderedPaintings = props.paintings.sort((a,b) => Number(b.node.context.title) - Number(a.node.context.title))
      const gridItems = orderedPaintings.map((painting) => {
        const image = props.images.filter(item => item.Key.endsWith(painting.node.context.topimage))
        const year = painting.node.context.title
        return (
          <Link 
          to={`${props.available ? '/available' : ''}/${year}`} 
          className={yearStyles.cell}>
            {image.length === 1 ? <GatsbyImage
              style={{ position: "absolute", minHeight: '100%', minWidth: '100%'}}
              image={getImage(image[0].localFile)}
              alt={"placeholder"}
            />
            : null
            }
            <span className={yearStyles.title_cont}><span className={yearStyles.title}>{year}</span></span>
          </Link>
    );
  });

  return (
    <myContext.Consumer>
    {context => (
    <>
      <div className={gridStyles.header}>
        <button onClick={() => context.toggleSideNav()} className={"timeline_btn"}><BiDotsVertical/>timeline</button>
        <h2>{props.title}</h2>
      </div>
      <div className={`${gridStyles.grid} ${yearStyles.grid}`}>{gridItems}</div>
    </>
    )}
    </myContext.Consumer>
  );
}
