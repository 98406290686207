import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";

import YearsGrid from '../components/YearsGrid'
import Timeline from '../components/timeline'
import {Helmet} from "react-helmet";

export default function All({data}) {
    const paintings = data.allSitePage.edges
    const topImages = paintings.map(d => d.node.context.topimage)
    const images = data.images.nodes.filter(i => topImages.indexOf(i.Key) !== -1)
    return (
        <>
            <Helmet htmlAttributes={{lang: 'en-uk'}}>
                <meta charSet="utf-8"/>
                <title>Alan Macdonald | Paintings</title>
                <meta name="description"
                      content="Alan Macdonald Paintings, paintings by Alan Macdonald, Allan Macdonald, Alan Mcdonald, Allan Mcdonald, Alan MacDonald, artist, painter, painting, paintings, British artists, British painters, British art, Duncan of Jordanstone College of Art, Scottish art, Scottish painters"/>
                <meta name="keywords" content="Alan Macdonald, all paintings"/>
                <meta name="author" content="Alan Macdonald"/>
                <meta charSet="utf-8"/>
                <link rel="canonical" href=""/>
                <meta property="og:title" content='Alan Macdonald'/>
                <meta property="og:description" content="Index of paintings by Alan Macdonald."/>
            </Helmet>
            <Layout current={{title: 'All'}}>
                <Timeline current={{title: 'All'}}/>
                <main>
                    <div>
                        <YearsGrid main={true} images={images} title={"Years"} paintings={paintings}/>
                    </div>
                </main>
            </Layout>
        </>
    );
}

export const query = graphql`query {
  allSitePage(filter: {context: {standardYear: {eq: true}}}) {
    edges {
      node {
        context {
          title
          topimage
          title
        }
      }
    }
  }
    images: allS3Object {
          nodes {
            Key
            localFile {
                relativePath
                childImageSharp {
                    gatsbyImageData(
                      width: 400
                      layout: CONSTRAINED
                      placeholder: DOMINANT_COLOR
                      formats: [JPG]
                    )
                  }
              }
            
      }
    }
  }`