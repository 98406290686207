import React, { useEffect, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import * as timelineStyles from "./timeline.module.css";

function YearLink(props){
    const refLink = useRef(null);

    useEffect(() => {
      if (props.current === props.to) {refLink.current.parentNode.parentNode.scrollTop = refLink.current.parentNode.offsetTop - (refLink.current.parentNode.parentNode.offsetHeight/2) + 100} 
    },[]);

    return (
    <li>
    <Link ref={refLink} Style={props.current === props.to ? 'color:#bba040' : null} activeStyle={{ color: "#bba040" }} to={`${props.to}`}>
      {props.children}
    </Link>
  </li>
    )
}


export default function Timeline(props) {
  let data = useStaticQuery(graphql`
  query TimelineQuery {
    standard: allSitePage(filter: {context: {standardYear: {eq: true}}}) {
      edges {
        node {
          context {
            title
          }
        }
      }
    }
  
    available:  allSitePage(filter: {context: {availableYear: {eq: true}}}) {
        edges {
          node {
            context {
              title
            }
          }
        }
      }
    }
  `);

  let orderedYears
  props.available ?  
  orderedYears = data.available.edges.sort((a,b) => Number(b.node.context.title) - Number(a.node.context.title))
  :
  orderedYears = data.standard.edges.sort((a,b) => Number(b.node.context.title) - Number(a.node.context.title))

  const yearLinks = orderedYears.map((item) => {
    const year = item.node.context.title;
    return (
      <YearLink current={props.current} to={`/${props.available ? 'available/' : ''}${year}`}>{year}</YearLink>
    );
  });

  return (
    <div className={timelineStyles.cont}>
      <ul className={timelineStyles.timeline}>
        <li>
          <Link activeStyle={{ color: "var(--heading-color)" }} to={`/${props.available ? 'available/' : ''}all`}>
            all
          </Link>
        </li>
        {yearLinks}
      </ul>
    </div>
  );
}
